import {
  Box,
  Button,
  createStyles,
  LoadingOverlay,
  Select,
  SelectProps,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { isEmpty } from 'lodash/fp';
import React, { forwardRef, useMemo, useState } from 'react';

import {
  ActiveConnectedPartnerType,
  OrganizationType,
  Referral,
  useEditCustomerDetails,
} from '@portals/api/partners';
import { DetailsPanel, NameAbbreviationAvatar } from '@portals/core';
import { DetailsList, DetailsListProps } from '@portals/framework';
import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';
import { useOpenModal } from '@portals/redux';

import { useRedirectToCustomerPortal } from '../../hooks/customers.hooks';
import { UnsavedChangesModalProps } from '../../modals';

interface CustomerDetailsPanelProps {
  customer: OrganizationType;
  onClose: () => void;
  connectedPartners: ActiveConnectedPartnerType[] | undefined;
  onClickedRow: React.Dispatch<React.SetStateAction<string | null>>;
}

interface ReferralSelectProps
  extends Omit<SelectProps, 'styles' | 'itemComponent' | 'data'> {
  data: Array<{
    label: Referral['referral_channel'];
    value: Referral['referral_channel'];
    disabled?: boolean;
  }>;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
}
const ReferralChannelSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, ...others }: ItemProps, ref) => (
    <Box ref={ref} {...others}>
      <Tooltip label={label}>
        <Text maw={120} truncate>
          {label}
        </Text>
      </Tooltip>
    </Box>
  )
);

const ReferralBySelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, ...others }: ItemProps, ref) => (
    <Box ref={ref} {...others}>
      <Tooltip label={label}>
        <Text maw={120} truncate>
          {label}
        </Text>
      </Tooltip>
    </Box>
  )
);

export function CustomerDetailsPanel({
  customer,
  onClose,
  connectedPartners,
  onClickedRow,
}: CustomerDetailsPanelProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();
  const redirectToCustomerPortal = useRedirectToCustomerPortal();
  const editCustomerDetails = useEditCustomerDetails();

  const [referralChannel, setReferralChannel] = useState<
    string | null | undefined
  >(customer.referral?.referral_channel);

  const [isChangesSaved, setIsChangesSaved] = useState(false);

  const referralByOptions = useMemo(
    () =>
      connectedPartners?.map((activeConnection) => {
        return {
          label: activeConnection.display_name,
          value: activeConnection.partner_id,
        };
      }) || [],
    [connectedPartners]
  );

  const [referral, setReferral] = useState<string | null>(
    referralByOptions.find(
      (referralByOption) =>
        referralByOption.label === customer.referral?.referral_name
    )?.value || null
  );

  const onSave = (closeUnsavedChangesModal?: () => void) => {
    editCustomerDetails.mutate(
      {
        channel: referralChannel,
        referrer_id: referralChannel === 'Channel Partner' ? referral : null,
        customerId: customer.id,
      },
      {
        onSuccess: () => {
          setIsChangesSaved(true);

          closeUnsavedChangesModal?.();
          onClose();
        },
      }
    );
  };

  const isCustomerDetailsChanged = useMemo(
    () =>
      (referral && referral !== customer.referral?.referral_id) ||
      referralChannel !== customer.referral?.referral_channel,
    [
      customer.referral?.referral_channel,
      customer.referral?.referral_id,
      referral,
      referralChannel,
    ]
  );

  const onCloseClick = () => {
    if (!isChangesSaved && isCustomerDetailsChanged) {
      onClickedRow(customer.id);

      if (referralChannel === 'Channel Partner' && !referral) {
        openModal<UnsavedChangesModalProps['data']>('UnsavedChangesModal', {
          onSave: (closeModal) => {
            closeModal?.();
          },
          onCloseDrawer: () => {
            onClickedRow(null);
            onClose();
          },
          title: 'Some details are missing',
          description: 'Please select value for the required fields.',
          confirmLabel: 'Keep editing',
        });

        return;
      }

      openModal<UnsavedChangesModalProps['data']>('UnsavedChangesModal', {
        onSave: (closeModal) => {
          onClickedRow(null);

          onSave(closeModal);
        },
        onCloseDrawer: () => {
          onClickedRow(null);
          onClose();
        },
        title: 'You have unsaved changes',
        description: 'Would you like to save your changes?',
        confirmLabel: 'Save changes',
      });
    } else {
      onClickedRow(null);
      onClose();
    }
  };

  const ref = useClickOutside(onCloseClick, ['mouseup', 'touched']);

  const referralChannelValues: ReferralSelectProps['data'] = useMemo(
    () => [
      { label: 'OEM', value: 'OEM' },
      { label: 'Self Sign-up', value: 'Self Sign-up' },
      {
        label: 'Channel Partner',
        value: 'Channel Partner',
        disabled: isEmpty(connectedPartners),
      },
    ],
    [connectedPartners]
  );

  const adjustedDetailsList = useMemo(() => {
    const detailsList: DetailsListProps['items'] = [
      {
        label: 'Users',
        value: customer.users,
      },
      {
        label: 'Devices',
        value: customer.devices,
      },
      {
        label: 'Referral channel',
        value: (
          <Select
            value={referralChannel}
            data={referralChannelValues}
            onChange={setReferralChannel}
            placeholder="Select referral"
            itemComponent={ReferralChannelSelectItem}
            data-testid="referral-selection-dropdown"
          />
        ),
      },
    ];

    if (referralChannel !== 'Channel Partner') {
      return detailsList;
    }

    return [
      ...detailsList,
      {
        label: 'Referral by',
        value: (
          <Select
            value={referral}
            data={referralByOptions}
            onChange={setReferral}
            placeholder="Select partner"
            itemComponent={ReferralBySelectItem}
            data-testid="referral-by-dropdown"
          />
        ),
      },
    ];
  }, [
    customer.devices,
    customer.users,
    referral,
    referralByOptions,
    referralChannel,
    referralChannelValues,
  ]);

  return (
    <Box className={classes.container} ref={ref}>
      <DetailsPanel pos="relative" bg="white" spacing="md">
        <LoadingOverlay visible={editCustomerDetails.isLoading} />

        <DetailsPanel.Header onClose={onCloseClick} spacing="xl">
          <DetailsPanel.Title>
            <Stack align="center" spacing="xs">
              <NameAbbreviationAvatar
                name={customer.name}
                src={customer.logo_url}
                radius="50%"
                size={56}
              />
              <Text size="lg" weight={500}>
                {customer.name}
              </Text>
            </Stack>
          </DetailsPanel.Title>

          <DetailsPanel.Actions grow={false}>
            <DetailsPanel.ActionButton
              fullWidth
              leftIcon={<Eye />}
              size="sm"
              onClick={() =>
                redirectToCustomerPortal.redirect({
                  customerId: customer.id,
                  afterAuthRedirectPathname: `/`,
                })
              }
            >
              Visit portal
            </DetailsPanel.ActionButton>
          </DetailsPanel.Actions>
        </DetailsPanel.Header>

        <DetailsPanel.Body>
          <DetailsList
            title="Portal info"
            items={adjustedDetailsList}
            groupProps={{
              align: 'center',
              grow: true,
              className: classes.detailsList,
            }}
          />
        </DetailsPanel.Body>

        {isCustomerDetailsChanged && (
          <DetailsPanel.Footer>
            <Button
              fullWidth
              onClick={() => onSave()}
              disabled={referralChannel === 'Channel Partner' && !referral}
              data-testid="save-changes-button"
            >
              Save changes
            </Button>
          </DetailsPanel.Footer>
        )}
      </DetailsPanel>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    height: '100%',
  },

  detailsList: {
    '> *': {
      flexGrow: 0,
    },
  },
}));
