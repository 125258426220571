import { createStyles, LoadingOverlay } from '@mantine/core';
import React, { useMemo } from 'react';

import { useStoreManagementStats } from '@portals/api/partners';
import {
  DashboardContentLayout,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as Box } from '@portals/icons/linear/box.svg';
import { ReactComponent as CalendarIcon } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as DollarCircle } from '@portals/icons/linear/dollar-circle.svg';
import { ReactComponent as StickyNote } from '@portals/icons/linear/stickynote.svg';
import { ReactComponent as TruckFast } from '@portals/icons/linear/truck-fast.svg';

import { PageCard } from './PageCard';

export function StoreManagement() {
  const { classes } = useStyles();
  const { canView } = usePermissionAccess();
  const storeManagementStats = useStoreManagementStats();

  const ordersRequiringApprovalBadge = useMemo(() => {
    if (!storeManagementStats.isFetched) return '';

    const numOfOrders =
      storeManagementStats.data?.orders_requiring_approval ?? 0;

    if (numOfOrders === 0) return '';

    return `${numOfOrders} ${
      numOfOrders > 1 ? 'orders require' : 'order requires'
    } approval`;
  }, [storeManagementStats.data, storeManagementStats.isFetched]);

  const ordersAwaitingShipmentBadge = useMemo(() => {
    if (!storeManagementStats.isFetched) return '';

    const numOfOrders =
      storeManagementStats.data?.orders_awaiting_shipment ?? 0;

    if (numOfOrders === 0) return '';

    return `${numOfOrders} ${
      numOfOrders > 1 ? 'orders await' : 'order awaits'
    } shipment`;
  }, [storeManagementStats.data, storeManagementStats.isFetched]);

  const invoicesPendingBadge = useMemo(() => {
    if (!storeManagementStats.isFetched) return '';

    const numOfPendingInvoices =
      storeManagementStats.data?.invoices_pending ?? 0;

    if (numOfPendingInvoices === 0) return '';

    return `${numOfPendingInvoices} ${
      numOfPendingInvoices > 1 ? 'invoices pending' : 'invoice pending'
    }`;
  }, [storeManagementStats.data, storeManagementStats.isFetched]);

  const subscriptionsPendingBadge = useMemo(() => {
    if (!storeManagementStats.isFetched) return '';

    const numOfPendingSubscriptions =
      storeManagementStats.data?.subscriptions_pending ?? 0;

    if (numOfPendingSubscriptions === 0) return '';

    return `${numOfPendingSubscriptions} ${
      numOfPendingSubscriptions > 1
        ? 'pending cancellations'
        : 'pending cancellation'
    }`;
  }, [storeManagementStats.data, storeManagementStats.isFetched]);

  return (
    <DashboardContentLayout pageTitle="Store Management" bg="gray.0">
      <LoadingOverlay visible={!storeManagementStats.isFetched} />

      {storeManagementStats.isFetched ? (
        <div className={classes.container}>
          {canView(['store_management', 'finance', 'fulfillment']) ? (
            <PageCard
              Icon={CalendarIcon}
              title="Subscriptions"
              description="Review and manage subscriptions"
              actionLabel="Show All Subscriptions"
              to="/store-management/subscriptions"
              value={storeManagementStats.data?.subscriptions}
              badgeLabel={subscriptionsPendingBadge}
              badgeLink="/store-management/subscriptions/active?q[status_eq]=pending_cancellation"
            />
          ) : null}

          {canView(['store_management', 'finance', 'fulfillment']) ? (
            <PageCard
              Icon={Box}
              title="Products Sold"
              description="Review all sold products"
              actionLabel="Show All Sold Products"
              to="/store-management/purchased-products"
              value={storeManagementStats.data?.sold_products}
            />
          ) : null}

          {canView(['finance', 'fulfillment']) ? (
            <PageCard
              Icon={StickyNote}
              title="Pending Orders"
              description="Review and manage customers' orders"
              actionLabel="Show All Orders"
              to="/store-management/orders"
              value={storeManagementStats.data?.pending_orders}
              badgeLabel={ordersRequiringApprovalBadge}
              badgeLink="/store-management/orders/pending"
            />
          ) : null}

          {canView(['finance', 'fulfillment']) ? (
            <PageCard
              Icon={TruckFast}
              title="Active Shipments"
              description="Manage orders shippings"
              actionLabel="Show All Shipments"
              to="/store-management/shipping"
              value={storeManagementStats.data?.active_shipments}
              badgeLabel={ordersAwaitingShipmentBadge}
              badgeLink="/store-management/shipping/processing"
            />
          ) : null}

          {canView(['finance']) ? (
            <PageCard
              Icon={DollarCircle}
              title="Invoices"
              description="Generate, review and manage invoices"
              actionLabel="Show All Invoices"
              to="/store-management/invoices"
              value={storeManagementStats.data?.invoices}
              badgeLabel={invoicesPendingBadge}
              badgeLink="/store-management/invoices/pending"
            />
          ) : null}
        </div>
      ) : null}
    </DashboardContentLayout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
    gap: theme.spacing.xl,
    placeContent: 'start',
  },
}));
