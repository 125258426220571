import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  ORGANIZATIONS_API_URL,
  organizationsQueryKeys,
} from './organizations.constants';
import { OrganizationType } from './organizations.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { uiGlobalQueryKeys } from '../../ui/global-query-keys';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { monitoringQueryKeys } from '../monitoring/monitoring.constants';

export function useOrganizations() {
  return useApiQuery<OrganizationType[]>(
    ORGANIZATIONS_API_URL,
    organizationsQueryKeys.all
  );
}

export interface UseCreateOrganizationParams {
  organization_name: string;
  name: string;
  email: string;
  main_partner_id?: string;
  auto_admin?: boolean;
  is_sandbox?: boolean;
}

export function useCreateOrganization() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'POST',
  });

  return useMutation<
    { cec_customer_exists?: true },
    ServerError,
    UseCreateOrganizationParams
  >({
    mutationFn: (params) =>
      fetchApiRequest(url, { ...options, body: JSON.stringify(params) }),
    onSuccess: (response) => {
      if (response.cec_customer_exists) return;

      dispatch(toastrSuccess('Organization created successfully'));

      queryClient.invalidateQueries(organizationsQueryKeys.all);

      queryClient.invalidateQueries(monitoringQueryKeys.customers.all());

      queryClient.invalidateQueries(uiGlobalQueryKeys.users);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateOrganization',
      baseUrl: ORGANIZATIONS_API_URL,
      method: 'POST',
    },
  });
}

interface UseEditCustomerDetailsParams {
  channel: string;
  referrer_id: string;
  customerId: string;
}

export function useEditCustomerDetails() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseEditCustomerDetailsParams>({
    mutationFn: ({ channel, referrer_id, customerId }) =>
      fetchApiRequest(`${url}/${customerId}/referrer`, {
        ...options,
        body: JSON.stringify({
          referrer_id,
          channel,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(organizationsQueryKeys.all);

      queryClient.invalidateQueries(monitoringQueryKeys.customers.all());

      queryClient.invalidateQueries(uiGlobalQueryKeys.users);

      toastrSuccess('Your changes have been saved successfully');
    },
    onError: ({ error }) => {
      toastrError(error || 'Could not save changes');
    },
    meta: {
      mutationName: 'useEditCustomerDetails',
      baseUrl: ORGANIZATIONS_API_URL,
      method: 'POST',
    },
  });
}

export interface UseEditIsCustomerSandboxParams {
  is_sandbox: boolean;
  customerId: string;
}

export function useEditIsCustomerSandbox() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'PUT',
  });

  return useMutation<void, ServerError, UseEditIsCustomerSandboxParams>({
    mutationFn: ({ customerId, is_sandbox }) =>
      fetchApiRequest(`${url}/${customerId}`, {
        ...options,
        body: JSON.stringify({ is_sandbox }),
      }),
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(organizationsQueryKeys.all);
      queryClient.invalidateQueries(monitoringQueryKeys.customers.all());
      queryClient.invalidateQueries(uiGlobalQueryKeys.users);

      if (params.is_sandbox) {
        toastrSuccess('Tenant successfully set as a test tenant');
      } else {
        toastrSuccess('Tenant successfully unset as a test tenant');
      }
    },
    onError: ({ error }) => {
      toastrError(error || 'Could not save changes');
    },
    meta: {
      mutationName: 'useEditIsCustomerSandbox',
      baseUrl: ORGANIZATIONS_API_URL,
      method: 'POST',
    },
  });
}

interface UseDeleteCustomerParams {
  customerId: string;
  customerName: string;
}

export function useDeleteCustomer() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, UseDeleteCustomerParams>({
    mutationFn: ({ customerId }) =>
      fetchApiRequest(`${url}/${customerId}`, {
        ...options,
      }),
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(organizationsQueryKeys.all);

      toastrSuccess(`Customer ${params.customerName} was successfully deleted`);
    },
    onError: ({ error }) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useDeleteCustomer',
      baseUrl: ORGANIZATIONS_API_URL,
      method: 'DELETE',
    },
  });
}
