import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useState } from 'react';
import { AutoSizer } from 'react-virtualized';

import { DeviceModelType, useEditDeviceModel } from '@portals/api/partners';
import { PermissionAccess, usePermissionAccess } from '@portals/framework';
import { ReactComponent as Save2 } from '@portals/icons/linear/save-2.svg';
import { AccessLevelEnum } from '@portals/types';
import { JsonEditor } from '@portals/ui';

interface ConfigSchemaProps {
  model: DeviceModelType;
}

export function ConfigSchema({ model }: ConfigSchemaProps) {
  const { classes } = useStyles();
  const { canEdit } = usePermissionAccess();
  const editDevice = useEditDeviceModel();

  const [isValid, setIsValid] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(model.is_config_schema_readonly);
  const [updatedConfigSchema, setUpdatedConfigSchema] = useState(
    model.config_schema || ''
  );

  const isPristine =
    updatedConfigSchema === model.config_schema &&
    isReadOnly === model.is_config_schema_readonly;

  const updateSchema = () => {
    editDevice.mutate({
      id: model.id,
      config_schema: updatedConfigSchema,
      is_config_schema_readonly: isReadOnly,
    });
  };

  return (
    <Box className={classes.container}>
      <Stack spacing="xs">
        <Title order={6} color="blue_gray.7" weight={400}>
          Configuration Schema
        </Title>
        <Text size="sm" color="blue_gray.6">
          Define the schema of the device's configuration
        </Text>
      </Stack>

      <Box className={classes.content} p="xl">
        <Stack>
          <p>
            The configuration editor can either show a simple JSON based config
            or a more complex editor to the end user. <br />
            Advanced editor is defined using the{' '}
            <a
              href="https://json-schema.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Json Schema Specification
            </a>
          </p>

          <Text color="red_accent.4">
            NOTE: only versions 7 and below are supported!
          </Text>
        </Stack>

        <Box className={classes.editorWrapper}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <JsonEditor
                data={updatedConfigSchema}
                onChange={setUpdatedConfigSchema}
                onValid={setIsValid}
                aceEditorProps={{
                  height: `${height}px`,
                  readOnly: !canEdit('models'),
                }}
              />
            )}
          </AutoSizer>
        </Box>

        <Group position="right">
          <PermissionAccess
            minAccessLevel={AccessLevelEnum.Edit}
            permissionKeys="models"
          >
            <Checkbox
              checked={isReadOnly}
              label="Read Only"
              labelPosition="right"
              onChange={(event) => setIsReadOnly(event.target.checked)}
            />
          </PermissionAccess>

          <PermissionAccess
            minAccessLevel={AccessLevelEnum.Edit}
            permissionKeys="models"
          >
            <Button
              disabled={isPristine || !isValid}
              onClick={updateSchema}
              leftIcon={<Save2 width={17} height={17} />}
            >
              Save Changes
            </Button>
          </PermissionAccess>
        </Group>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
  },
  content: {
    backgroundColor: theme.colors.gray[1],
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    borderRadius: theme.radius.lg,
    gap: theme.spacing.md,
  },
  editorWrapper: {
    border: `1px solid ${theme.colors.gray[3]}`,
    overflow: 'hidden',
  },
}));
