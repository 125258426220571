import {
  createStyles,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import React, { ReactNode } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { useOpenModal } from '@portals/redux';

import { CustomerPortalPaths } from './customer-portal.constants';
import { useCustomerPortalBrandingPages } from './customer-portal.hooks';
import { ReactComponent as ConnectIcon } from '../../../assets/img/connect-plus.svg';
import { DASHBOARD_PATHS } from '../../../routes/dashboard/dashboard-paths.constants';
import { SidebarTabPanel } from '../SidebarTabPanel';

export function CustomerPortalSidebar() {
  const { classes } = useStyles();

  const openModal = useOpenModal();

  const match = useMatch(DASHBOARD_PATHS.staticPaths.branding);

  const { privateCloudBrandingPages, cecBrandingPages } =
    useCustomerPortalBrandingPages();

  const selected = privateCloudBrandingPages
    .concat(cecBrandingPages)
    .find((subPage) => subPage.path === match?.params?.['*']);

  if (selected && selected.isAccessible) {
    return (
      <SidebarTabPanel>
        <SidebarTabPanel.Header>
          <Link to="." className={classes.headerBackLink}>
            <ChevronDownIcon className={classes.headerChevron} />
            <Text>{selected.label}</Text>
          </Link>
        </SidebarTabPanel.Header>

        <SidebarTabPanel.Body>{selected.formComponent}</SidebarTabPanel.Body>
      </SidebarTabPanel>
    );
  }

  return (
    <SidebarTabPanel>
      <SidebarTabPanel.Header>
        <Text>Customer Portal</Text>
      </SidebarTabPanel.Header>

      <SidebarTabPanel.Body>
        <Stack spacing="xxl">
          {privateCloudBrandingPages.length > 0 && (
            <SidebarSection title="PRIVATE CLOUD">
              {privateCloudBrandingPages.map((subPage) => (
                <SubPageLink
                  key={subPage.path}
                  to={subPage.path}
                  label={subPage.label}
                  icon={subPage.icon}
                />
              ))}
            </SidebarSection>
          )}

          <SidebarSection title="XYTE CONNECT">
            {cecBrandingPages.map((subPage) => {
              if (subPage.isAccessible) {
                return (
                  <SubPageLink
                    key={subPage.path}
                    to={subPage.path}
                    label={subPage.label}
                    icon={subPage.icon}
                  />
                );
              }

              return (
                <UnstyledButton
                  key={subPage.path}
                  className={classes.linkItem}
                  onClick={() => openModal('RequestCecEnrollmentModal')}
                >
                  <Group spacing="xs">
                    <span className={classes.linkItemIcon}>{subPage.icon}</span>

                    <Text>{subPage.label}</Text>
                  </Group>

                  <ConnectIcon />
                </UnstyledButton>
              );
            })}
          </SidebarSection>
        </Stack>
      </SidebarTabPanel.Body>
    </SidebarTabPanel>
  );
}

interface SidebarSectionProps {
  title: string;
  children: ReactNode;
}

function SidebarSection({ title, children }: SidebarSectionProps) {
  return (
    <Stack>
      <Text fz="xs" c="gray.5">
        {title}
      </Text>

      <Stack spacing="xs">{children}</Stack>
    </Stack>
  );
}

interface SubPageLinkProps {
  to: CustomerPortalPaths;
  label: ReactNode;
  icon: ReactNode;
}

function SubPageLink({ to, icon, label }: SubPageLinkProps) {
  const { classes } = useStyles();

  return (
    <Link key={to} to={to} className={classes.linkItem}>
      <Group spacing="xs">
        <span className={classes.linkItemIcon}>{icon}</span>

        <Text>{label}</Text>
      </Group>

      <ChevronDownIcon className={classes.chevron} />
    </Link>
  );
}

const useStyles = createStyles((theme) => ({
  headerBackLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.xs,
    color: 'inherit',

    ...theme.fn.hover({
      color: 'inherit',
      textDecoration: 'none',
    }),
  },
  headerChevron: {
    transform: 'rotate(90deg)',
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 72,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.lg,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    backgroundColor: theme.colors.gray[0],

    ...theme.fn.hover({
      color: 'inherit',
      textDecoration: 'none',
      backgroundColor: theme.colors.gray[1],
    }),
  },
  linkItemIcon: {
    display: 'flex',
    alignItems: 'center',

    svg: {
      width: 18,
      height: 18,
    },
  },
  chevron: {
    color: theme.colors.gray[4],
    transform: 'rotate(-90deg)',
  },
}));
