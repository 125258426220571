import { Badge, Group, LoadingOverlay } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { Row } from 'react-table';

import {
  OrganizationType,
  useActiveConnectionsResponseWithFilters,
  useBulkCreateCustomers,
  useEditIsCustomerSandbox,
} from '@portals/api/partners';
import { SelfUserResponseType } from '@portals/api/ui';
import {
  ModalButton,
  PermissionAccess,
  useConfirmationModal,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import {
  AvatarCell,
  BooleanCell,
  DateCell,
  RowMenuItem,
  RowMenuItems,
  SmartTable,
} from '@portals/table';
import {
  AccessLevelEnum,
  PaginatedFilterTypeEnum,
  RowMenuWrapperType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { CustomerDetailsPanel } from './CustomerDetailsPanel';
import { RedirectToCustomerPortalButton } from '../../components/RedirectToCustomerPortalButton';
import {
  DeleteCustomerModalProps,
  SelectInvitationTypeModalProps,
} from '../../modals';

interface TableRowData extends OrganizationType {
  controlled: boolean;
}

function mapRow(
  org: OrganizationType,
  accessibleOrganizations: OrganizationsTableProps['accessibleOrganizations']
) {
  return {
    ...org,
    controlled: find({ id: org.id }, accessibleOrganizations) !== undefined,
  };
}

export function CustomersTable({
  organizations,
  accessibleOrganizations = [],
}: OrganizationsTableProps) {
  const [clickedRowId, setClickedRowId] = useState<string | null>(null);

  const openModal = useOpenModal();

  const bulkCreateCustomers = useBulkCreateCustomers();
  const setIsCustomerSandbox = useEditIsCustomerSandbox();
  const asyncConfirmationCheck = useConfirmationModal();

  const { isSuperAdmin } = usePermissionAccess();

  const activeConnectionsResponseWithFilters =
    useActiveConnectionsResponseWithFilters({
      filters: [
        {
          id: 'connected_partner_classification',
          value: 'channel_partner',
          type: PaginatedFilterTypeEnum.Eq,
        },
      ],
    });

  async function onToggleSandbox(customer: OrganizationType) {
    const isSandbox = customer.is_sandbox;

    const isConfirmed = await asyncConfirmationCheck({
      title: isSandbox ? `Unset as sandbox tenant` : `Set as sandbox tenant`,
      description: isSandbox
        ? `This tenant will no longer be a test tenant and will be included in reporting and analytics.`
        : 'This tenant will be set as a test tenant and will be excluded from reporting and analytics.',
      confirmationLabel: 'Approve',
      confirmButtonProps: {
        color: 'primary',
      },
    });

    if (isConfirmed) {
      setIsCustomerSandbox.mutate({
        customerId: customer.id,
        is_sandbox: !isSandbox,
      });
    }
  }

  const tableColumns = useMemo<Array<TableColumn<TableRowData>>>(() => {
    const columns: Array<TableColumn<TableRowData>> = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        isSticky: true,
        minWidth: 500,
        maxWidth: 500,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, { name, logo_url, lab, is_sandbox }) => (
          <Group align="center" noWrap>
            <AvatarCell label={name} src={logo_url} />

            {lab ? (
              <Badge
                color="indigo_accent"
                variant="filled"
                size="lg"
                fw={300}
                sx={{ flexShrink: 0 }}
              >
                Lab
              </Badge>
            ) : null}

            {is_sandbox ? (
              <Badge
                color="purple_accent"
                variant="filled"
                size="lg"
                fw={300}
                sx={{ flexShrink: 0 }}
              >
                Sandbox
              </Badge>
            ) : null}
          </Group>
        ),
      },
      {
        dataField: 'users',
        text: 'Users',
        sort: true,
        maxWidth: 150,
        filter: {
          type: TableFilterTypeEnum.Number,
        },
      },
      {
        dataField: 'devices',
        text: 'Devices',
        sort: true,
        maxWidth: 150,
        filter: {
          type: TableFilterTypeEnum.Number,
        },
      },
      {
        dataField: 'email',
        text: 'Contact',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
      },
      {
        dataField: 'referral.referral_channel',
        text: 'Referral channel',
        sort: true,
        formatter: (_, row) => row.referral?.referral_channel || 'N/A',
        minWidth: 250,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'referral.referral_name',
        text: 'Referred by',
        minWidth: 200,
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'created_at',
        text: 'Created',
        maxWidth: 180,
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Date,
        },
        formatter: (_, row) => (
          <DateCell date={row.created_at} withTimeAgo={false} />
        ),
      },
    ];

    columns.push({
      dataField: 'controlled',
      text: 'Access',
      sort: true,
      formatter: (_, row) => {
        if (!row.controlled) {
          return <BooleanCell isActive={false} label="No access" />;
        }

        return (
          <RedirectToCustomerPortalButton customerId={row.id}>
            Visit portal
          </RedirectToCustomerPortalButton>
        );
      },
    });

    return columns;
  }, []);

  const tableData = useMemo<TableRowData[]>(
    () => organizations.map((org) => mapRow(org, accessibleOrganizations)),
    [organizations, accessibleOrganizations]
  );

  const getRowMenuItems = (
    row: Row<TableRowData>,
    wrapperProps: RowMenuWrapperType
  ) => {
    const items: RowMenuItem[] = [];

    if (!row.original.lab) {
      items.push({
        id: 'is_sandbox',
        label: row.original.is_sandbox
          ? 'Unset as sandbox tenant'
          : 'Set as sandbox tenant',
        onClick: () => {
          wrapperProps.menuRef?.onClose?.();
          onToggleSandbox(row.original);
        },
      });
    }

    if (isSuperAdmin) {
      items.push({
        id: 'delete',
        label: 'Delete customer',
        color: 'red',
        Icon: Trash,
        onClick: () => {
          openModal<DeleteCustomerModalProps['data']>('DeleteCustomerModal', {
            customer: row.original,
          });
          wrapperProps.menuRef?.onClose();
        },
      });
    }

    return items;
  };

  return (
    <>
      <LoadingOverlay
        visible={activeConnectionsResponseWithFilters.isLoading}
      />

      <SmartTable<TableRowData>
        keyField="id"
        name="partners.customers"
        data={tableData}
        columns={tableColumns}
        noDataIndication={{ title: 'No customers' }}
        disabledRowClickId={clickedRowId}
        additionalActions={() => (
          <PermissionAccess
            minAccessLevel={AccessLevelEnum.Edit}
            permissionKeys={['customers', 'finance', 'fulfillment']}
          >
            <ModalButton
              modalName="SelectInvitationTypeModal"
              label="Create Customer"
              size="sm"
              data-testid="create-customer-button"
              leftIcon={<AddSquare />}
              data={
                {
                  title: 'How would you like to invite customers?',
                  directInvitationText:
                    'Create customers and send an email invitation directly. This option is swift, precise and ideal for individual invitations.',
                  bulkInvitationText:
                    'Invite all your customers at once. Our support team will reach out to you, assisting in the creation and import of a CSV file.',
                  onDirectInvitationClick: () => openModal('AddOrganization'),
                  onBulkInvitationClick: () => bulkCreateCustomers.mutate(),
                } satisfies SelectInvitationTypeModalProps['data']
              }
            />
          </PermissionAccess>
        )}
        defaultSorted={[{ id: 'name', desc: false }]}
        rowMenu={({ row, wrapperProps }) => {
          const menuItems = getRowMenuItems(row, wrapperProps);
          return (
            menuItems.length > 0 && (
              <RowMenuItems wrapperProps={wrapperProps} items={menuItems} />
            )
          );
        }}
        detailsPanel={{
          type: 'page',
          width: 400,
          withClickOutside: false,
          renderer: ({ row, onClose }) => (
            <CustomerDetailsPanel
              customer={row.original}
              onClose={onClose}
              connectedPartners={activeConnectionsResponseWithFilters.data}
              onClickedRow={setClickedRowId}
            />
          ),
        }}
      />
    </>
  );
}

interface OrganizationsTableProps {
  organizations: OrganizationType[];
  accessibleOrganizations?: SelfUserResponseType['accessible_tenants']['organizations'];
}
