import React, { useEffect, useMemo } from 'react';

import { usePartnerConfig } from '@portals/api/partners';
import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { AuditLogs, SettingsPage, SettingsPageProps } from '@portals/framework';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { ReactComponent as Blend2 } from '@portals/icons/linear/blend-2.svg';
import { ReactComponent as Briefcase } from '@portals/icons/linear/briefcase.svg';
import { ReactComponent as Category } from '@portals/icons/linear/category.svg';
import { ReactComponent as Global } from '@portals/icons/linear/global.svg';
import { ReactComponent as Key } from '@portals/icons/linear/key.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as MessageText1 } from '@portals/icons/linear/message-text-1.svg';
import { ReactComponent as Notification } from '@portals/icons/linear/notification.svg';
import { ReactComponent as ProfileCircle } from '@portals/icons/linear/profile-circle.svg';
import { ReactComponent as Puzzle } from '@portals/icons/linear/puzzle.svg';
import { ReactComponent as ReceiptItem } from '@portals/icons/linear/receipt-item.svg';
import { ReactComponent as Shop } from '@portals/icons/linear/shop.svg';
import { ReactComponent as TaskSquare } from '@portals/icons/linear/task-square.svg';
import { ReactComponent as Verify } from '@portals/icons/linear/verify.svg';
import { useOpenModal } from '@portals/redux';

import { MESSAGES_LIST } from './audit-log-messages/MessagesList';
import { Contacts } from './Contacts';
import { CustomerPortalFeatures } from './customer-portal-features/CustomerPortalFeatures';
import { EditApiKeys } from './EditApiKeys';
import EditNotifications from './EditNotifications';
import { General } from './general/General';
import { PartnerToPartnerConnection } from './partner-to-partner-connection/PartnerToPartnerConnection';
import { PlanAndBilling } from './plan-and-billing/PlanAndBilling';
import Provision from './Provision';
import { ReferralCodesTable } from './referral-codes/ReferralCodesTable';
import { StoreSettings } from './store-settings';
import { Support } from './support/Support';
import { UpgradePlanBadge } from './UpgradePlanBadge';
import { UsersAndGroups } from './users-and-groups/UsersAndGroups';
import EditIntegrations from '../../components/Integrations/EditIntegrations';
import { usePricingPlanFeature } from '../../hooks/partner-config';

export function Settings() {
  const openModal = useOpenModal();

  const currentUser = useCurrentUser();

  const { pages, basePath } = useSettingsPage();

  useEffect(
    function showNewSettingsPageFeatureModal() {
      if (
        currentUser.data?.feature_notifications?.find(
          (feature) => feature === FeatureNotificationEnum.NewSettingsDesign
        )
      ) {
        openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
          featureNotification: FeatureNotificationEnum.NewSettingsDesign,
        });
      }
    },
    [currentUser.data?.feature_notifications, openModal]
  );

  return (
    <SettingsPage basePath={basePath} pages={pages} rootPageTitle="Settings" />
  );
}

export function useSettingsPage() {
  const partnerConfig = usePartnerConfig();
  const deviceModelsLimit = usePricingPlanFeature('device_models_limit');
  const apiAccess = usePricingPlanFeature('api_access');

  const pages = useMemo<SettingsPageProps['pages']>(() => {
    return [
      {
        id: 'usersAndGroups',
        path: '/users_and_groups',
        title: 'Users & Groups',
        icon: <ProfileCircle />,
        description: `Create new users, groups, and manage the user's permissions for each group.`,
        component: <UsersAndGroups />,
      },
      {
        id: 'provision',
        path: '/provision',
        title: 'Provision',
        icon: <Verify />,
        description:
          'Manage your provisioning settings, such as devices provision data, SSL, target URLs and manufacturers short code',
        component: <Provision />,
        isRouteBlocked: deviceModelsLimit === 0,
        visible: deviceModelsLimit !== undefined && deviceModelsLimit > 0,
      },
      {
        id: 'apiKeys',
        path: '/api_keys',
        title: 'API Keys',
        icon: <Key />,
        description:
          'Manage your API keys, which are used by other applications or services to access your data',
        component: <EditApiKeys />,
        isRouteBlocked: apiAccess === 0,
        ribbon:
          apiAccess === 0 ? (
            <UpgradePlanBadge source="Partners > Settings Page > API keys > upgrade badge" />
          ) : undefined,
      },
      {
        id: 'notifications',
        path: '/notifications',
        title: 'Notifications',
        icon: <Notification />,
        description:
          'Manage your notification settings, such as email notifications for new referral, new tickets, as well as webhook configuration for device notifications',
        component: <EditNotifications />,
      },
      {
        id: 'referrals',
        path: '/referrals',
        title: 'Referrals',
        icon: <Global />,
        description:
          'Easily generate and oversee referral links that allow channel partners to distribute links to their customers and track the source of each customer.',
        component: <ReferralCodesTable />,
      },
      {
        id: 'integrations',
        path: '/integrations',
        title: 'Integrations',
        icon: <Puzzle />,
        description:
          'Manage integrations with other applications or services, such as CRM systems, ticketing systems,  UC platforms, and more',
        component: <EditIntegrations />,
        visible: false,
      },
      {
        id: 'contacts',
        path: '/contacts',
        title: 'Contacts',
        icon: <MessageText1 />,
        description:
          'Manage the finance and admin contacts of your partner portal',
        component: <Contacts />,
      },
      {
        id: 'audit_logs',
        path: '/audit_logs',
        title: 'Audit Logs',
        icon: <TaskSquare />,
        description:
          'View and manage audit logs, which provide a record of all actions taken within your tenant, including user activity and system events',
        component: (
          <AuditLogs
            messagesList={MESSAGES_LIST}
            getUserRoute={(userId) => `/settings/users_and_groups/users`}
          />
        ),
      },
      {
        id: 'planAndBilling',
        path: '/plan_and_billing',
        title: 'Plan & Billing',
        icon: <ReceiptItem />,
        description:
          'View and manage your plan settings, including upgrading  your plan, adding support seats, and deleting your account',
        component: <PlanAndBilling />,
      },
      {
        id: 'support',
        path: '/support',
        title: 'Support',
        icon: <Lifebuoy />,
        description:
          'Manage customer support, such as adding and managing support seats, activate and deactivate your customers support center, and more',
        component: <Support />,
      },
      {
        id: 'partnersConnection',
        path: '/partners_connection',
        title: 'Partners Connection',
        icon: <Blend2 />,
        description:
          'Lets you manage partner connections, including enabling or disabling partner to partner connection, and configuring sharing settings',
        component: <PartnerToPartnerConnection />,
      },
      {
        id: 'store',
        path: '/store',
        title: 'Store',
        icon: <Shop />,
        description:
          'Configure and manage your online store, including managing payment methods, currencies, policies and setting up tax settings',
        component: <StoreSettings />,
        ribbon:
          partnerConfig?.classification === 'channel_partner' ? (
            <UpgradePlanBadge source="Partners > Settings Page > Store > upgrade badge" />
          ) : null,
        isRouteBlocked: partnerConfig?.classification === 'channel_partner',
      },
      {
        id: 'customerPortalFeatures',
        path: '/customer_portal_features',
        title: 'Customer Portal Features',
        icon: <Category />,
        description:
          'Enable or disable features like warranties and device management within the customer portal.',
        component: <CustomerPortalFeatures />,
        ribbon:
          partnerConfig?.classification === 'channel_partner' ? (
            <UpgradePlanBadge source="Partners > Settings Page > Customer Portal Features > upgrade badge" />
          ) : null,
        isRouteBlocked: partnerConfig?.classification === 'channel_partner',
      },
      {
        id: 'general',
        path: '/general',
        title: 'General',
        icon: <Briefcase />,
        description:
          'Customize company details, logo, and theme settings for a personalized user experience.',
        component: <General />,
      },
    ].sort((a, b) => a.title.localeCompare(b.title));
  }, [apiAccess, deviceModelsLimit, partnerConfig?.classification]);

  return {
    pages,
    basePath: '/settings',
  };
}
