import { Center, createStyles, Text } from '@mantine/core';
import React from 'react';

import { AuthPagesBackgroundCEC } from './AuthPagesBackgroundCEC';
import { useBrandingContext } from '../../../branding.context';
import { BrowserMock } from '../../../browser-mock/BrowserMock';

export function AuthPagesPreviewCEC() {
  const { classes } = useStyles();

  const { portalConfig } = useBrandingContext();

  const logoSrc = portalConfig.design?.cec_auth_page?.logo;
  const tagline = portalConfig.design?.cec_auth_page?.tagline;

  return (
    <Center className={classes.container}>
      <BrowserMock
        favicon={portalConfig.favicon}
        windowTitle={portalConfig.window_title}
        domain="app.xyte.io"
        className={classes.browserMock}
      >
        <AuthPagesBackgroundCEC
          logo={logoSrc && <img width="100%" src={logoSrc} alt="logo" />}
          tagline={
            <Text color="gray.9" ta="center">
              {tagline}
            </Text>
          }
        />
      </BrowserMock>
    </Center>
  );
}

const useStyles = createStyles({
  container: {
    height: '100%',
  },
  browserMock: {
    width: '85%',
  },
});
