import {
  Box,
  Button,
  createStyles,
  Grid,
  Paper,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import React from 'react';

import { TroubleshootingStepType } from '@portals/api/partners';
import { getWidgetIcon } from '@portals/device-widgets';

import { ReactComponent as Danger } from '../../../../assets/img/icons/danger.svg';

interface TroubleshootingPreviewProps {
  steps: Array<TroubleshootingStepType & { id: string }>;
}

export function TroubleshootingPreview({ steps }: TroubleshootingPreviewProps) {
  const { classes } = useStyles();

  return (
    <Stack
      mih={800}
      px="xl"
      w="100%"
      align="center"
      justify="center"
      bg="gray.1"
    >
      <Paper
        radius="lg"
        p="xl"
        w="100%"
        maw={480}
        shadow="md"
        display="grid"
        className={classes.container}
      >
        <Stack w="100%" align="center">
          <Danger width={66} height={66} />

          <Text size="md" data-testid="device-claiming-failed-title">
            Device Claiming Failed
          </Text>

          <Text size="xs" align="center">
            We couldn't claim your device. Please check the following and try
            again:
          </Text>
        </Stack>

        <Box
          w="100%"
          sx={{
            overflow: 'hidden',
          }}
        >
          <ScrollArea.Autosize w="100%" mah={450}>
            <Stack w="100%">
              {steps.map((step) => {
                const { Icon } = getWidgetIcon(step.icon);

                return (
                  <Box key={step.id} className={classes.stepContainer} w="100%">
                    <Stack align="start">
                      <Icon width={16} height={16} />
                    </Stack>

                    <Stack spacing="xs">
                      <Text color={step.title ? 'gray.9' : 'gray.5'}>
                        {step.title || 'Issue title'}
                      </Text>

                      <Textarea
                        color={step.description ? 'gray.6' : 'gray.4'}
                        autosize
                        value={step.description}
                        readOnly
                        placeholder="Detailed description, which can help users prevent the issue or maintain their device better."
                        classNames={{
                          input: classes.textAreaInput,
                        }}
                      />
                    </Stack>
                  </Box>
                );
              })}
            </Stack>
          </ScrollArea.Autosize>
        </Box>

        <Stack w="100%" align="center">
          <Text size="xs" align="center">
            If this issue persists, please contact support for further
            assistance.
          </Text>

          <Grid columns={2} w="100%">
            <Grid.Col span={1}>
              <Button
                data-analyticsid="troubleshooting-contact-support"
                variant="default"
                w="100%"
                size="xs"
              >
                Contact support
              </Button>
            </Grid.Col>

            <Grid.Col span={1}>
              <Button
                data-analyticsid="troubleshooting-try-again"
                w="100%"
                size="xs"
                data-testid="try-again-button"
              >
                Try again
              </Button>
            </Grid.Col>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    gridTemplateRows: `max-content minmax(max-content, 1fr) max-content`,
    justifyItems: 'center',
    gap: 16,
  },
  stepContainer: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: theme.spacing.md,
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  textAreaInput: {
    border: 'none',
    padding: 0,
    width: '100%',
    borderRadius: 0,
    overflow: 'hidden',
    minHeight: 'unset',
    color: theme.colors.gray[6],
  },
}));
