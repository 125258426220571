import {
  Avatar,
  Button,
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Text,
} from '@mantine/core';
import { first } from 'lodash/fp';
import React from 'react';

import { IncomingInvitation } from '@portals/api/partners';
import { prettyTimeMoment } from '@portals/utils';

interface IncomingInvitationCardProps {
  invitation: IncomingInvitation;
  isLoading: boolean;
  onAccept: (invitationId: string) => void;
  onReject: (invitationId: string) => void;
}

export function IncomingInvitationCard({
  invitation,
  isLoading,
  onAccept,
  onReject,
}: IncomingInvitationCardProps) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.container}>
      <LoadingOverlay visible={isLoading} />

      <Group align="stretch" position="apart">
        <Group spacing="lg">
          <Avatar
            radius="xl"
            size={45}
            color="blue"
            src={invitation.partner.logo}
          >
            {first(invitation.partner.display_name)}
          </Avatar>

          <Text size="md" color="gray.8">
            {invitation.partner.display_name}
          </Text>
        </Group>

        <Group align="center" spacing="xl">
          <Text size="xs" color="gray.5">
            {prettyTimeMoment(invitation.created_at)}
          </Text>

          <Divider orientation="vertical" color="gray.2" />

          <Group spacing="xs">
            <Button
              size="xs"
              variant="default"
              w={95}
              onClick={() => onReject(invitation.id)}
            >
              Reject
            </Button>

            <Button
              size="xs"
              variant="light"
              w={95}
              onClick={() => onAccept(invitation.id)}
              data-testid="accept-partner-connection-button"
            >
              Accept
            </Button>
          </Group>
        </Group>
      </Group>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
  },
}));
