import { getOr } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { DEVICE_API, API } from '@portals/redux';
import { setValue, updateData } from '@portals/redux/actions/data';

import { PriorityOption } from '../../pages/dev-center/OpenIncident';

export const setAuth = setValue('dev', 'auth');

export const getApiDevice = (state) => getOr({}, 'data.dev.auth', state);

export const getDeviceInfo = (id) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}`,
    name: 'getDeviceInfo',
  },
});

export const updateDevice = (id, data) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}`,
    method: 'PUT',
    data,
    name: 'updateDevice',
  },
});

export const sendTelemetry = (
  id,
  data,
  accessToken = null,
  extraActions = null
) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/telemetry`,
    data,
    name: 'sendTelemetry',
    accessToken,
    success: extraActions,
  },
});

export const sendChildTelemetries = (id, data) => ({
  type: DEVICE_API,
  payload: {
    method: 'POST',
    url: `v1/devices/${id}/children/telemetries`,
    data,
    name: 'sendChildTelemetries',
  },
});

export const getConfig = (id) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/config`,
    name: 'getConfig',
  },
});

export const setConfig = (id, data) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/config`,
    data,
    name: 'setConfig',
  },
});

export const getCommand = (id) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/command`,
    name: 'getCommand',
  },
});

export const updateCommand = (id, data) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/command`,
    data,
    name: 'updateCommand',
  },
});

export const sendDump = (id, mimeType, filename, data) => ({
  type: DEVICE_API,
  payload: {
    method: 'POST',
    url: `v1/devices/${id}/dumps/${encodeURIComponent(mimeType)}/${filename}`,
    contentType: 'plain/text',
    data,
    name: 'sendDump',
  },
});

export const appendDump = (
  deviceId: string,
  dumpId: string,
  data: Record<string, string>
) => ({
  type: DEVICE_API,
  payload: {
    method: 'PUT',
    url: `v1/devices/${deviceId}/dumps/${dumpId}`,
    contentType: 'plain/text',
    data,
    name: 'appendDump',
  },
});

export const getLicense = (id) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/license`,
    name: 'getLicense',
  },
});

export const getLicenses = (id: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/licenses`,
    name: 'getLicenses',
  },
});

export const updateLicense = (id, data) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/licenses`,
    data,
    name: 'updateLicense',
  },
});

export const fetchStats = () => ({
  type: API,
  payload: {
    url: 'ui/partner/stats',
    success: updateData('stats'),
    name: 'fetchStats',
  },
});

export const getChildDevices = (id: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/children`,
    name: 'getChildDevices',
  },
});

export const getSpaceInfo = (id: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/space`,
    name: 'getSpaceInfo',
  },
});

export const getFiles = (id: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/files`,
    name: 'getFiles',
  },
});

export const getFile = (deviceId: string, fileId: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}/files/${fileId}`,
    name: 'getFile',
  },
});

export const getIncidents = (id: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${id}/incidents`,
    name: 'getIncidents',
  },
});

export const deleteDevice = (deviceId: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}`,
    name: 'deleteDevice',
    method: 'DELETE',
  },
});

export const closeIncident = (incidentId: string, deviceId: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}/incidents/${incidentId}`,
    name: 'closeIncident',
    method: 'DELETE',
  },
});

export const closeIncidents = (deviceId: string) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}/incidents`,
    name: 'closeIncidents',
    method: 'DELETE',
  },
});

interface CloudSettings {
  property: string;
  value: string;
}

export const setCloudSettings = (deviceId: string, data: CloudSettings) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}/cloud_settings`,
    name: 'setCloudSettings',
    method: 'PUT',
    data,
  },
});

interface openIncidentData {
  title: string;
  priority?: PriorityOption | null;
  description?: string;
  issue?: string;
}

export const openIncident = (deviceId: string, data: openIncidentData) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}/incidents`,
    name: 'openIncident',
    data,
  },
});

interface AddEventData {
  name: string;
  category: string;
  int_key_1?: number;
  int_key_2?: number;
  string_key_1?: string;
  string_key_2?: string;
  timestamp?: string;
}

export const addEvent = (deviceId: string, data: AddEventData) => ({
  type: DEVICE_API,
  payload: {
    url: `v1/devices/${deviceId}/events`,
    data,
    name: 'addEvent',
  },
});

interface RegisterChildDeviceData {
  parentId: string;
  deviceInfo: {
    model_id: string;
    firmware_version: string;
    sub_model?: string;
    sn?: string;
    mac?: string;
    name?: string;
  };
}

export const registerChildDevice = (
  registerChildDeviceInfo: RegisterChildDeviceData
) => ({
  type: DEVICE_API,
  payload: {
    method: 'POST',
    url: `v1/devices/${registerChildDeviceInfo.parentId}/children`,
    data: { ...registerChildDeviceInfo.deviceInfo },
    name: 'registerChildDevice',
  },
});

export function useGetApiDevice() {
  return useSelector(getApiDevice);
}
