import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { useBrandingContext } from '../../branding.context';
import { DesignColorInput } from '../../common/fields/ColorField';
import { DesignImageInput } from '../../common/fields/ImageField';
import { PremiumSwitch } from '../../common/fields/PremiumSwitch';
import { SegmentedControlField } from '../../common/fields/SegmentedControlField';
import { TextareaField } from '../../common/fields/TextareaField';

export function AuthPagesForm() {
  const { classes } = useStyles();

  const { portalConfig } = useBrandingContext();

  return (
    <Stack spacing="md">
      <Text className={classes.sectionTitle}>Branding</Text>

      <DesignImageInput
        valuePath="design.sign_in_page.logo"
        field={{
          title: 'Logo',
          name: 'logo',
          cropConfig: {
            aspectRatio: 1,
            height: 100,
            width: 100,
          },
          previewConfig: {
            width: 100,
            height: 100,
          },
          height: 200,
          inputProps: {
            mb: 15,
          },
        }}
      />

      <DesignImageInput
        valuePath="design.sign_in_page.login_image"
        field={{
          title: 'Login image',
          name: 'hero',
          height: 200,
          previewConfig: {
            width: 150,
            height: 150,
          },
          cropConfig: {
            aspectRatio: 1,
            width: 500,
            height: 500,
          },
          inputProps: {
            mb: 15,
          },
        }}
      />

      <TextareaField
        label="Tagline"
        placeholder="Supercharging your HaaS business"
        valuePath="design.sign_in_page.tagline"
      />

      <PremiumSwitch />

      <Text className={classes.sectionTitle}>Background</Text>

      <SegmentedControlField
        options={[
          {
            label: 'Color',
            value: 'color',
          },
          {
            label: 'Image',
            value: 'image',
          },
        ]}
        valuePath="design.sign_in_page.background_mode"
      />

      <Stack spacing="xl">
        {portalConfig?.design?.sign_in_page?.background_mode === 'image' ? (
          <DesignImageInput
            valuePath="design.sign_in_page.background_image"
            field={{
              title: 'Background image',
              name: 'background_image',
              height: 200,
              cropConfig: {
                aspectRatio: 0.5,
                height: 1440,
                width: 720,
              },
              previewConfig: {
                width: 90,
                height: 180,
              },
            }}
          />
        ) : (
          <DesignColorInput
            label="Background Color"
            valuePath="design.sign_in_page.background_color"
          />
        )}

        <DesignColorInput
          label="Tagline color"
          valuePath="design.sign_in_page.tagline_color"
        />
      </Stack>

      <Text className={classes.sectionTitle}>Color Palette</Text>

      <Stack spacing="xl">
        <DesignColorInput
          label="Primary color"
          valuePath="design.sign_in_page.primary_color"
        />
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.blue_gray[5],
  },
}));
