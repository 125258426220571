import { Button, createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { useRevokeUserSupportSeat, UserResponseType } from '@portals/api/ui';
import { usersEmptyStateSrc } from '@portals/assets';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { AvatarCell, DateCell, RowMenuItems, SmartTable } from '@portals/table';
import { TableColumn } from '@portals/types';

import {
  useUsersWithoutSupportSeats,
  useUsersWithSupportSeats,
} from './support.hooks';
import { AssignSupportSeatToUserModalProps } from '../../../modals';

const COLUMNS: TableColumn<UserResponseType>[] = [
  {
    dataField: 'name',
    text: 'Name',
    formatter: (_, user) => (
      <AvatarCell src={null} label={user.name} description={user.email} />
    ),
  },
  {
    dataField: 'sign_in_count',
    text: 'Login Count',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    dataField: 'last_sign_in_at',
    text: 'Last Seen',
    formatter: (_, user) => <DateCell date={user.last_seen_at} />,
  },
];

export function Members() {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const asyncConfirmation = useConfirmationModal();

  const usersWithSupportSeats = useUsersWithSupportSeats();
  const eligibleUsers = useUsersWithoutSupportSeats();
  const revokeUserSupportSeat = useRevokeUserSupportSeat();

  const openAssignSupportSeatToUserModal = () => {
    openModal<AssignSupportSeatToUserModalProps['data']>(
      'AssignSupportSeatToUserModal',
      { usersList: eligibleUsers }
    );
  };

  const onRevokeUserSupportSeat = async (userId: string) => {
    const isConfirmed = await asyncConfirmation({
      description: "Are you sure you want to revoke this user's support seat?",
    });

    if (isConfirmed) {
      revokeUserSupportSeat.mutate({ userId });
    }
  };

  return (
    <Paper withBorder p="xl" radius="md" h="100%" mih={600}>
      <Stack spacing="xl" h="100%" className={classes.content}>
        <Text size="md" color="gray.8">
          Members
        </Text>

        <SmartTable<UserResponseType>
          noDataIndication={{
            title: 'No members with support seats yet',
            subtitle:
              'Assign a support seat to a member to give them support access for your clients',
            assetSrc: usersEmptyStateSrc,
            actions: (
              <Button
                variant="light"
                onClick={openAssignSupportSeatToUserModal}
              >
                Add Member
              </Button>
            ),
          }}
          name="partners.settings.support.members"
          keyField="id"
          exportParams={{ isEnabled: false }}
          noColumnsSelection
          data={usersWithSupportSeats}
          columns={COLUMNS}
          additionalActions={() => (
            <Button onClick={openAssignSupportSeatToUserModal}>
              Add Member
            </Button>
          )}
          rowMenu={({ row, wrapperProps }) => (
            <RowMenuItems
              wrapperProps={wrapperProps}
              items={[
                {
                  id: 'revoke',
                  label: 'Revoke',
                  color: 'red',
                  Icon: Trash,
                  onClick: () => {
                    wrapperProps.menuRef?.onClose();
                    onRevokeUserSupportSeat(row.original.id);
                  },
                },
              ]}
            />
          )}
        />
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gridAutoColumns: '100%',

    '.table-scroll-container': {
      padding: 0,
      border: 'none',
    },
  },
}));
