import { Text } from '@mantine/core';
import React from 'react';

import {
  PurchasedProductSummaryType,
  usePurchasedProducts,
} from '@portals/api/partners';
import { DashboardContentLayout } from '@portals/framework';
import {
  AvatarCell,
  BadgeCell,
  DateCell,
  PaginatedTable,
} from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

import { PurchasedProductDetailsPanel } from './PurchasedProductDetailsPanel';

const COLUMNS: Array<TableColumn<PurchasedProductSummaryType>> = [
  {
    dataField: 'product_name',
    text: 'Name',
    sort: true,
    isSticky: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) => (
      <AvatarCell src={product.image_url} label={product.name} />
    ),
  },
  {
    dataField: 'organization_display_name',
    text: 'Customer',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { buyer }) => {
      if (!buyer) return '--';

      return (
        <AvatarCell
          src={buyer.logo_url}
          label={buyer.name || '--'}
          radius="md"
          withAbbreviation={Boolean(buyer.name)}
        />
      );
    },
  },
  {
    dataField: 'order_reference_id',
    text: 'Order Reference ID',
    minWidth: 200,
    formatter: (_, { order_reference_id }) => (
      <Text>{!order_reference_id || '' ? '--' : order_reference_id}</Text>
    ),
  },
  {
    dataField: 'product_category',
    text: 'Category',
    sort: true,
    filter: { type: TableFilterTypeEnum.Text },
    formatter: (_, { product }) =>
      product.category ? (
        <BadgeCell
          label={product.category}
          sx={(theme) => ({
            backgroundColor: theme.black,
            color: theme.white,
            fontWeight: 400,
            textTransform: 'uppercase',
          })}
        />
      ) : (
        '--'
      ),
  },
  {
    dataField: 'shareable_token',
    text: 'Shareable Code',
    minWidth: 230,
    filter: { type: TableFilterTypeEnum.Text },
    sort: true,
    formatter: (_, { shareable_token }) => (
      <Text>{shareable_token ?? '--'}</Text>
    ),
  },
  {
    dataField: 'device.sn',
    text: 'Serial number',
    minWidth: 220,
    filter: { type: TableFilterTypeEnum.Text },
    sort: true,
    formatter: (_, { device }) => <Text>{device?.sn ?? '--'}</Text>,
  },
  {
    dataField: 'device.mac',
    text: 'MAC address',
    minWidth: 220,
    filter: { type: TableFilterTypeEnum.Text },
    sort: true,
    formatter: (_, { device }) => <Text>{device?.mac ?? '--'}</Text>,
  },
  {
    dataField: 'redeemed_at',
    text: 'Redeemed at',
    sort: true,
    filter: { type: TableFilterTypeEnum.Date },
    formatter: (_, { redeemed_at }) =>
      redeemed_at ? <DateCell date={redeemed_at} /> : '--',
  },
  {
    dataField: 'created_at',
    text: 'Purchase Date',
    sort: true,
    filter: { type: TableFilterTypeEnum.Date },
    formatter: (_, { created_at }) => <DateCell date={created_at} />,
  },
];

export const PurchasedProducts = () => {
  return (
    <DashboardContentLayout pageTitle="Sold Products" bg="gray.0">
      <PaginatedTable<PurchasedProductSummaryType>
        columns={COLUMNS}
        dataHook={usePurchasedProducts}
        noDataIndication={{ title: 'No sold products' }}
        defaultSortBy={[{ id: 'created_at', desc: true }]}
        name="partners.store_management.purchased_products"
        keyField="id"
        detailsPanel={{
          type: 'page',
          renderer: ({ row, onClose }) => (
            <PurchasedProductDetailsPanel
              onClose={onClose}
              purchasedProductId={row.original.id}
            />
          ),
        }}
      />
    </DashboardContentLayout>
  );
};
