import { Button, Group, Select, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useDeviceModels } from '@portals/api/partners';

import Response from '../../components/Response';
import TestCommand from '../../components/TestCommand';
import { registerChildDevice, useGetApiDevice } from '../../redux/actions/api';

export function RegisterChildDevice() {
  const form = useForm({
    initialValues: {
      model_id: '',
      firmwareVersion: '',
      subModel: '',
      sn: '',
      mac: '',
      name: '',
    },
  });

  const dispatch = useDispatch();
  const access = useGetApiDevice();

  const onSubmit = (values: typeof form.values) => {
    dispatch(
      registerChildDevice({
        parentId: access.id,
        deviceInfo: {
          model_id: values.model_id,
          mac: values.mac,
          sn: values.sn,
          sub_model: values.subModel,
          firmware_version: values.firmwareVersion,
          name: values.name,
        },
      })
    );
  };

  const deviceModels = useDeviceModels();

  const deviceModelsOptions = useMemo(() => {
    if (!deviceModels.isFetched) {
      return [];
    }

    return map(({ id, model }) => {
      return {
        value: id,
        label: model,
      };
    }, deviceModels.data);
  }, [deviceModels]);

  return (
    <Stack>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <Stack>
            <Select
              label="Device Model"
              searchable
              multiple={false}
              data={deviceModelsOptions}
              {...form.getInputProps('model_id')}
            />

            <TextInput
              {...form.getInputProps('firmwareVersion')}
              label="Frimware version"
              required
            />

            <TextInput {...form.getInputProps('mac')} label="Mac" />

            <TextInput {...form.getInputProps('sn')} label="Sn" />

            <TextInput {...form.getInputProps('subModel')} label="Sub model" />

            <TextInput {...form.getInputProps('name')} label="Name" />
          </Stack>

          <Group>
            <Button type="submit" disabled={!access.id}>
              Register
            </Button>
            <span>
              Device ID:
              {access.id || 'Not set'}
            </span>
          </Group>
        </Stack>
      </form>

      {access.id ? <TestCommand url={`/${access.id}`} /> : null}

      <Response type="registerChildDevice" />
    </Stack>
  );
}
