import { generatePath } from 'react-router-dom';

import { SETTING_PAGE_ID_URL_PARAM_NAME } from '@portals/framework';

const STATIC_PATHS = {
  root: '/',

  // Home Hub
  getStarted: '/home',
  dashboardOverview: '/dashboard',
  partners: '/partners/*',
  customers: '/customers',
  settings: `/settings/:${SETTING_PAGE_ID_URL_PARAM_NAME}?/*`,
  settingsCreateTaxGroup: `/settings/store/create-tax-group`,

  // Product Hub
  productHub: '/product',
  deviceModels: '/models',
  deviceModel: '/models/:id/*',
  devices: '/devices',
  files: '/files',
  file: '/files/:id',
  devCenter: '/dev-center',
  rule: '/rules/:id',

  // Commerce Hub
  commerceHub: '/commerce',
  productCatalog: '/catalog/*',
  storeListings: '/products/*',
  storeManagement: '/store-management/*',
  orders: '/store-management/orders/*',
  subscriptions: '/store-management/subscriptions/*',
  soldProducts: '/store-management/purchased-products',

  // Operations Hub
  shipping: '/store-management/shipping/:tab?',

  // Finance Hub
  financeHub: '/finance',
  creditProviders: '/credit-providers',
  invoices: '/store-management/invoices/*',
  financeOrders: '/finance/orders/*',

  // Support Hub
  supportHub: '/support',
  supportOverview: '/support-overview',
  tickets: '/tickets',
  ticket: '/tickets/:id',
  ticketsAll: '/tickets/all',
  ticketsActive: '/tickets/active',
  incidents: '/incidents/:id?',
  supportedCustomers: '/organizations',

  // Marketing Hub
  branding: '/branding/:type?/*',

  // Route modals
  customerRouteModal: '/m/customers/:customer_id',
  orderRouteModal: '/m/order/:order_id',
  offlineOrderRouteModal: '/m/offline-order/:order_id',
  creditProvidersRouteModal: '/m/credit-providers',
  integrationsRouteModal: '/m/integrations/:integration?',

  // Route panels
  storeListingRoutePanel: '/p/products/:store_listing_slug',
  subscriptionRoutePanel: '/p/subscription/:subscription_id',
  purchasedProductRoutePanel: '/p/purchased_product/:purchased_product_id',
} as const satisfies Record<string, `/${string}`>;

const DYNAMIC_PATHS = {
  deviceModel: (deviceModelId: string) =>
    generatePath(DASHBOARD_PATHS.staticPaths.deviceModel, {
      id: deviceModelId,
      '*': '',
    }),

  file: (fileId: string) =>
    generatePath(DASHBOARD_PATHS.staticPaths.file, { id: fileId }),

  ticket: (ticketId: string) =>
    generatePath(DASHBOARD_PATHS.staticPaths.ticket, { id: ticketId }),
} as const satisfies DynamicPaths;

export const DASHBOARD_PATHS: {
  staticPaths: StaticPaths;
  dynamicPaths: DynamicPaths;
} = {
  staticPaths: STATIC_PATHS,
  dynamicPaths: DYNAMIC_PATHS,
};

type StaticPaths = typeof STATIC_PATHS;
export type StaticPath = StaticPaths[keyof StaticPaths];

type DynamicPaths = Partial<
  Record<keyof StaticPaths, (...args: any[]) => string>
>;
