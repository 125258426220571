import { Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { dashboardSrc } from '@portals/assets';

interface ModelDashboardEmptyStateProps {
  children?: ReactNode;
}

export function MonitoringDashboardEmptyState({
  children,
}: ModelDashboardEmptyStateProps) {
  return (
    <Stack h="100%" ta="center" justify="center" align="center">
      <img src={dashboardSrc} alt="dashboard" />

      <Stack spacing={0}>
        <Text size="md" color="gray.8" mb="xs" weight={600}>
          Customize The Monitoring Dashboard with Widgets
        </Text>

        <Text size="sm" color="gray.5" mb="md">
          Make the most of your dashboard by adding relevant widgets
          <br /> and creating a customized experience
        </Text>
      </Stack>

      {children || null}
    </Stack>
  );
}
