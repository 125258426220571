import AddCurrency from './AddCurrency';
import { AddHardwareKey, AddHardwareKeyProps } from './AddHardwareKey';
import { AddModel, type AddModelProps } from './AddModel';
import { AddModelWidget } from './AddModelWidget';
import { AddOrganization } from './AddOrganization';
import {
  AddSupportSeatsModal,
  type AddSupportSeatsModalProps,
} from './AddSupportSeatsModal';
import { AddTaxAddress } from './AddTaxAddress';
import { AddUserModal } from './AddUserModal';
import {
  AssignSupportSeatToUserModal,
  type AssignSupportSeatToUserModalProps,
} from './AssignSupportSeatToUserModal';
import ChangeDomain from './ChangeDomain';
import { ChooseTaxGroup } from './choose-tax-group/ChooseTaxGroup';
import { ClaimDevice, type ClaimDeviceProps } from './ClaimDevice';
import { ConfigureModel } from './ConfigureModel';
import ConnectFlow from './ConnectFlow';
import { CreateOrderWizardModal } from './create-order/CreateOrderWizardModal';
import {
  OrderReferenceIdModal,
  OrderReferenceIdModalProps,
} from './create-order/order-details-step/OrderReferenceIdModal';
import {
  CreateShipmentModal,
  CreateShipmentModalProps,
} from './create-shipment/CreateShipmentModal';
import {
  CreateNewPartnerTypeModal,
  CreateNewPartnerTypeModalProps,
} from './CreateNewPartnerType/CreateNewPartnerTypeModal';
import { CreatePartner } from './CreatePartner';
import { CreateShipment } from './CreateShipment';
import { CreateTaxGroup } from './CreateTaxGroup/CreateTaxGroup';
import { CreditProviderApplication } from './CreditProviderApplication';
import {
  DeleteCustomerModal,
  DeleteCustomerModalProps,
} from './DeleteCustomerModal';
import { DeleteUserModal } from './DeleteUserModal';
import { DeviceConnected } from './DeviceConnected';
import DeviceNote from './DeviceNote';
import {
  AddDeviceWidgetModal,
  AddDeviceWidgetModalProps,
} from './devices-widgets/AddDeviceWidgetModal';
import {
  EditDeviceWidgetModal,
  EditDeviceWidgetModalProps,
} from './devices-widgets/EditDeviceWidgetModal';
import DeviceTelemetries from './DeviceTelemetries';
import {
  DuplicateSlugModal,
  type DuplicateSlugModalProps,
} from './DuplicateSlugModal';
import EditModelWidget from './EditModelWidget';
import { EditPartnerLogo } from './EditPartnerLogo';
import { FeatureFlags } from './FeatureFlags';
import { FileUploader, FileUploaderProps } from './FileUploader';
import {
  AddGroupMembersModal,
  type AddGroupMembersModalProps,
} from './groups/AddGroupMembersModal';
import {
  ManageGroupModal,
  type ManageGroupModalProps,
} from './groups/ManageGroupModal';
import InvitePartner from './InvitePartner';
import {
  ProductWizardModal,
  type ProductWizardModalData,
} from './product-wizard-modal/ProductWizardModal';
import { ProductCreationSuccessModal } from './ProductCreationSuccessModal';
import AddProductsPricing from './ProductsPricing/AddProductsPricing';
import ProductsPricing from './ProductsPricing/ProductsPricing';
import {
  AddRequiredStoreSettings,
  AddRequiredStoreSettingsModalData,
} from './publish-product-missing-requirements/AddRequiredStoreSettings';
import { PublishDeviceModelsSummarized } from './publish-product-missing-requirements/PublishDeviceModelsSummarized';
import { PublishProductsWizard } from './PublishProductsWizard/PublishProductsWizard';
import {
  ReferralCodeModal,
  type ReferralCodeModalProps,
} from './ReferralCodeModal';
import { RequestCecEnrollmentModal } from './request-cec-enrollment-modal/RequestCecEnrollmentModal';
import {
  ReviewPurchaseOrderModal,
  ReviewPurchaseOrderModalProps,
} from './review-purchase-order/ReviewPurchaseOrderModal';
import { ReviewReceiptModal } from './ReviewReceiptModal';
import AddRule from './Rules/AddRule';
import EditRule from './Rules/EditRule';
import { RunCommand } from './RunCommand';
import {
  SelectInvitationTypeModal,
  type SelectInvitationTypeModalProps,
} from './SelectInvitationTypeModal';
import SelectModel from './SelectModel';
import {
  StoreListingWizardModal,
  StoreListingWizardModalProps,
} from './store-listing-wizard/StoreListingWizardModal';
import { StoreListingsSortingModal } from './store-listings-sorting/StoreListingsSortingModal';
import {
  SubscriptionCancellationFlowModal,
  type SubscriptionCancellationFlowModalProps,
} from './subscription-cancellation/SubscriptionCancellationFlowModal';
import { AddSupportedCommand } from './SupportedCommand/Add';
import { CustomFieldForm } from './SupportedCommand/CustomFields/CustomFieldForm';
import { EditSupportedCommand } from './SupportedCommand/Edit';
import {
  ToggleUserSupportSeatModal,
  type ToggleUserSupportSeatModalProps,
} from './ToggleUserSupportSeatModal';
import {
  UnsavedChangesModal,
  UnsavedChangesModalProps,
} from './UnsavedChangesModal';
import { UpgradePricingPlan } from './UpgradePricingPlan';
import UploadInvoice from './UploadInvoice';

const MODALS = {
  AddCurrency,
  AddTaxAddress,
  ConfigureModel,
  AddProductsPricing,
  AddOrganization,
  AddRule,
  EditRule,
  FileUploader,
  UploadInvoice,
  ClaimDevice,
  DeviceNote,
  DeviceTelemetries,
  FeatureFlags,
  AddSupportedCommand,
  EditSupportedCommand,
  RunCommand,
  CreatePartner,
  EditPartnerLogo,
  AddModelWidget,
  AddDeviceWidgetModal,
  EditDeviceWidgetModal,
  EditModelWidget,
  AddModel,
  SelectModel,
  AddHardwareKey,
  ConnectFlow,
  DeviceConnected,
  InvitePartner,
  ChangeDomain,
  CreateShipment,
  CustomFieldForm,
  ProductsPricing,
  PublishProductsWizard,
  ChooseTaxGroup,
  CreateTaxGroup,
  CreditProviderApplication,
  UpgradePricingPlan,
  AddRequiredStoreSettings,
  PublishDeviceModelsSummarized,
  AddGroupMembersModal,
  ManageGroupModal,
  ReferralCodeModal,
  AddUserModal,
  AssignSupportSeatToUserModal,
  ProductWizardModal,
  StoreListingWizardModal,
  CreateNewPartnerTypeModal,
  CreateShipmentModal,
  ReviewPurchaseOrderModal,
  SelectInvitationTypeModal,
  AddSupportSeatsModal,
  ToggleUserSupportSeatModal,
  ReviewReceiptModal,
  DuplicateSlugModal,
  StoreListingsSortingModal,
  DeleteUserModal,
  SubscriptionCancellationFlowModal,
  ProductCreationSuccessModal,
  RequestCecEnrollmentModal,
  CreateOrderWizardModal,
  OrderReferenceIdModal,
  UnsavedChangesModal,
  DeleteCustomerModal,
};

export default MODALS;

export {
  type AddRequiredStoreSettingsModalData,
  type AddGroupMembersModalProps,
  type ManageGroupModalProps,
  type ReferralCodeModalProps,
  type AssignSupportSeatToUserModalProps,
  type ProductWizardModalData,
  type StoreListingWizardModalProps,
  type AddDeviceWidgetModalProps,
  type EditDeviceWidgetModalProps,
  type FileUploaderProps,
  type CreateNewPartnerTypeModalProps,
  type AddHardwareKeyProps,
  type AddModelProps,
  type CreateShipmentModalProps,
  type ReviewPurchaseOrderModalProps,
  type ClaimDeviceProps,
  type SelectInvitationTypeModalProps,
  type AddSupportSeatsModalProps,
  type ToggleUserSupportSeatModalProps,
  type SubscriptionCancellationFlowModalProps,
  type DuplicateSlugModalProps,
  type UnsavedChangesModalProps,
  type OrderReferenceIdModalProps,
  type DeleteCustomerModalProps,
};
