import { createStyles, LoadingOverlay } from '@mantine/core';
import { isEqual } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';

import {
  PortalConfigType,
  usePartnerConfig,
  usePortalConfig,
  useUpdatePortalConfig,
} from '@portals/api/partners';
import { defaultStoreBannerSrc } from '@portals/assets';

import { BrandingProvider } from './branding.context';
import { BrandingType } from './branding.types';
import { BrandingPreview } from './BrandingPreview';
import { BrandingSidebar } from './BrandingSidebar';
import { Footer } from './Footer';
import { DASHBOARD_PATHS } from '../../routes/dashboard/dashboard-paths.constants';

export function Branding() {
  const { classes, theme } = useStyles();
  const portalConfig = usePortalConfig();
  const updatePortalConfig = useUpdatePortalConfig();
  const params = useParams<{ type: BrandingType }>();

  const partnerConfig = usePartnerConfig();

  const [localPortalConfig, setLocalPortalConfig] = useState(portalConfig.data);

  const isPristine = useMemo(() => {
    if (updatePortalConfig.isLoading) return true;

    switch (params.type) {
      case BrandingType.CustomerPortal:
        return (
          isEqual(localPortalConfig?.design, portalConfig.data?.design) &&
          localPortalConfig?.window_title === portalConfig.data?.window_title &&
          localPortalConfig?.favicon === portalConfig?.data?.favicon
        );

      case BrandingType.Email:
        return isEqual(
          localPortalConfig?.email_settings,
          portalConfig?.data?.email_settings
        );

      case BrandingType.Store:
        return isEqual(
          localPortalConfig?.design?.store,
          portalConfig?.data?.design?.store
        );

      default:
        return true;
    }
  }, [
    updatePortalConfig.isLoading,
    params.type,
    localPortalConfig,
    portalConfig?.data,
  ]);

  const updateLocalPortalConfig = (updatedConfig: PortalConfigType) => {
    if (updatePortalConfig.isLoading) return;

    setLocalPortalConfig(updatedConfig);
  };

  const revertChanges = () => {
    if (updatePortalConfig.isLoading) return;

    setLocalPortalConfig(portalConfig.data);
  };

  const saveChanges = () => {
    if (!localPortalConfig) return;

    if (updatePortalConfig.isLoading) return;

    updatePortalConfig.mutate(
      { updatedConfig: localPortalConfig },
      {
        onSuccess: (updatedPortalConfig) =>
          setLocalPortalConfig(updatedPortalConfig),
      }
    );
  };

  const defaultValues = useMemo<
    Pick<PortalConfigType, 'design' | 'email_settings'>
  >(() => {
    const defaultDesign: PortalConfigType['design'] = {
      sign_in_page: {
        logo: '',
        login_image: '',
        tagline: null,
        tagline_color: theme.white,
        powered_by_xyte_label: true,
        background_mode: 'color',
        background_color: theme.colors.blue_accent[4],
        background_image: '',
        primary_color: theme.colors.blue_accent[4],
        text_color: theme.white,
      },
      cec_auth_page: {
        logo: '',
        tagline: '',
      },
      cec_devices: {
        logo: '',
      },
      cec_tickets: {
        logo: '',
      },
      navigation_menu: {
        logo: '',
      },
      store: {
        logo:
          partnerConfig?.logo_url ||
          portalConfig?.data?.design?.navigation_menu?.logo ||
          portalConfig?.data?.design?.sign_in_page?.logo ||
          'logo_full_dark.svg',
        tagline: '',
        banner: defaultStoreBannerSrc,
        with_banner: false,
      },
    };

    const defaultEmailSettings: PortalConfigType['email_settings'] = {
      custom_from_email: false,
      primary_color: theme.colors.gray[9],
      logo: 'logo_full_dark.svg',
      site_url: '',
      markdown: 'Thank you,\\\n **The XYTE team**',
      facebook_href: '',
      facebook_is_active: false,
      instagram_href: '',
      instagram_is_active: false,
      linkedin_href: '',
      linkedin_is_active: false,
      twitter_href: '',
      twitter_is_active: false,
    };

    return { design: defaultDesign, email_settings: defaultEmailSettings };
  }, [
    partnerConfig?.logo_url,
    portalConfig?.data?.design?.navigation_menu?.logo,
    portalConfig?.data?.design?.sign_in_page?.logo,
    theme.colors.blue_accent,
    theme.colors.gray,
    theme.white,
  ]);

  if (!params.type) {
    const path = generatePath(DASHBOARD_PATHS.staticPaths.branding, {
      type: BrandingType.CustomerPortal,
      '*': null,
    });

    return <Navigate to={path} replace />;
  }

  return (
    <BrandingProvider
      value={{
        portalConfig: localPortalConfig || {},
        updatePortalConfig: updateLocalPortalConfig,
        isLoading: updatePortalConfig.isLoading,
        isPristine,
        revertChanges,
        saveChanges,
        defaultValues,
      }}
    >
      <div className={classes.container}>
        <LoadingOverlay visible={portalConfig.isInitialLoading} />

        <div className={classes.content}>
          <BrandingSidebar />

          <BrandingPreview />
        </div>

        <Footer />
      </div>
    </BrandingProvider>
  );
}

const useStyles = createStyles(() => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: '100%',
  },
  content: {
    overflow: 'hidden',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
  },
}));
