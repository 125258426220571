import React, { ReactNode, useMemo } from 'react';

import {
  useFeatureFlags,
  useIsCecPartner,
  useIsCompletedCecMigration,
} from '@portals/api/partners';
import { isPartnerFeatureOn } from '@portals/framework';
import { ReactComponent as BarcodeIcon } from '@portals/icons/linear/barcode.svg';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as GlobalIcon } from '@portals/icons/linear/global.svg';
import { ReactComponent as LifebuoyIcon } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as ListIcon } from '@portals/icons/linear/list.svg';
import { ReactComponent as Login1Icon } from '@portals/icons/linear/login-1.svg';
import { ReactComponent as UnlimitedIcon } from '@portals/icons/linear/unlimited.svg';

import { AuthPagesForm } from './auth-pages/AuthPagesForm';
import { AuthPagesPreview } from './auth-pages/AuthPagesPreview';
import { AuthPagesFormCEC } from './auth-pages/cec-auth-pages/AuthPagesFormCEC';
import { AuthPagesPreviewCEC } from './auth-pages/cec-auth-pages/AuthPagesPreviewCEC';
import { ConnectForm } from './connect/ConnectForm';
import { ConnectPreview } from './connect/ConnectPreview';
import {
  CUSTOMER_PORTAL_PATHS,
  CustomerPortalPaths,
} from './customer-portal.constants';
import { DeviceClaimingForm } from './device-claiming/DeviceClaimingForm';
import { DeviceClaimingPreview } from './device-claiming/DeviceClaimingPreview';
import { DevicesForm } from './devices/DevicesForm';
import { DevicesPreview } from './devices/DevicesPreview';
import { NavigationMenuForm } from './navigation-menu/NavigationMenuForm';
import { NavigationMenuPreview } from './navigation-menu/NavigationMenuPreview';
import { PortalDomainForm } from './portal-domain/PortalDomainForm';
import { PortalDomainPreview } from './portal-domain/PortalDomainPreview';
import { TicketsForm } from './tickets/TicketsForm';
import { TicketsPreview } from './tickets/TicketsPreview';

interface SubPageItem {
  path: CustomerPortalPaths;
  label: ReactNode;
  icon: ReactNode;
  formComponent: ReactNode;
  previewComponent: ReactNode;
  isAccessible: boolean;
}

export function useCustomerPortalBrandingPages() {
  const featureFlags = useFeatureFlags();

  const isCecPartner = useIsCecPartner();
  const isCompletedCecMigration = useIsCompletedCecMigration();

  const isCecPreEnrollmentOn = isPartnerFeatureOn(
    featureFlags,
    'cec_pre_enrollment'
  );

  const canAccessPrivateCloudBranding =
    !isCecPartner || !isCompletedCecMigration;

  const canAccessCecBranding = isCecPartner || isCecPreEnrollmentOn;

  const privateCloudBrandingPages = useMemo<Array<SubPageItem>>(() => {
    if (!canAccessPrivateCloudBranding) {
      return [];
    }

    return [
      {
        path: CUSTOMER_PORTAL_PATHS.PortalDomain,
        label: 'Portal Domain',
        icon: <GlobalIcon />,
        formComponent: <PortalDomainForm />,
        previewComponent: <PortalDomainPreview />,
        isAccessible: canAccessPrivateCloudBranding,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.AuthPages,
        label: 'Auth Pages',
        icon: <Login1Icon />,
        formComponent: <AuthPagesForm />,
        previewComponent: <AuthPagesPreview />,
        isAccessible: canAccessPrivateCloudBranding,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.NavigationMenu,
        label: 'Navigation Menu',
        icon: <ListIcon />,
        formComponent: <NavigationMenuForm />,
        previewComponent: <NavigationMenuPreview />,
        isAccessible: canAccessPrivateCloudBranding,
      },
    ];
  }, [canAccessPrivateCloudBranding]);

  const cecBrandingPages = useMemo<Array<SubPageItem>>(() => {
    return [
      {
        path: CUSTOMER_PORTAL_PATHS.CecAuthPages,
        label: 'Auth Pages',
        icon: <Login1Icon />,
        formComponent: <AuthPagesFormCEC />,
        previewComponent: <AuthPagesPreviewCEC />,
        isAccessible: canAccessCecBranding,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.Connect,
        label: 'Connect',
        icon: <UnlimitedIcon />,
        formComponent: <ConnectForm />,
        previewComponent: <ConnectPreview />,
        isAccessible: canAccessCecBranding,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.DeviceClaiming,
        label: 'Device Claiming',
        icon: <BarcodeIcon />,
        formComponent: <DeviceClaimingForm />,
        previewComponent: <DeviceClaimingPreview />,
        isAccessible: canAccessCecBranding,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.Devices,
        label: 'Devices',
        icon: <BoxIcon />,
        formComponent: <DevicesForm />,
        previewComponent: <DevicesPreview />,
        isAccessible: canAccessCecBranding,
      },
      {
        path: CUSTOMER_PORTAL_PATHS.Tickets,
        label: 'Tickets',
        icon: <LifebuoyIcon />,
        formComponent: <TicketsForm />,
        previewComponent: <TicketsPreview />,
        isAccessible: canAccessCecBranding,
      },
    ];
  }, [canAccessCecBranding]);

  return {
    privateCloudBrandingPages,
    cecBrandingPages,
  };
}
