import { Center, createStyles } from '@mantine/core';
import React from 'react';

import { usePortalDomain } from '@portals/framework';

import { SignInBrowserMock } from './SignInBrowserMock';
import { useBrandingContext } from '../../branding.context';
import { BrowserMock } from '../../browser-mock/BrowserMock';

export function AuthPagesPreview() {
  const { classes } = useStyles();

  const portalDomain = usePortalDomain();
  const { portalConfig } = useBrandingContext();

  return (
    <Center className={classes.container}>
      <BrowserMock
        domain={portalDomain}
        windowTitle={portalConfig.window_title}
        favicon={portalConfig.favicon}
        className={classes.browserMock}
      >
        <SignInBrowserMock />
      </BrowserMock>
    </Center>
  );
}

const useStyles = createStyles({
  container: {
    height: '100%',
  },
  browserMock: {
    width: '85%',
  },
});
