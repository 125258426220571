import {
  Alert,
  Button,
  Checkbox,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  SegmentedControl,
  SegmentedControlProps,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React, { forwardRef, useState } from 'react';
import * as Yup from 'yup';

import {
  useActiveConnectionsResponse,
  useCreateOrganization,
  useIsChannelPartner,
  useRequestPlanUpgrade,
} from '@portals/api/partners';
import { EnvelopeWithShadowSVG } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import {
  ModalProps,
  PartnerAvatar,
  useHasSupportSeat,
} from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';

interface AddOrganizationProps extends ModalProps {}

type CustomerAdmin = 'customer' | 'you' | 'both';

const schema = Yup.object().shape({
  email: Yup.string().when('choose_admin', {
    is: (value: CustomerAdmin) => value === 'customer' || value === 'both',
    then: Yup.string().email('Please enter a valid email address'),
  }),
});

export function AddOrganization({ closeMe }: AddOrganizationProps) {
  const { classes, theme } = useStyles();

  const [isCecCustomerExistsModalOpen, setIsCecCustomerExistsModalOpen] =
    useState(false);

  const hasSupportSeat = useHasSupportSeat();
  const isChannelPartner = useIsChannelPartner();

  const requestPlanUpgrade = useRequestPlanUpgrade();
  const createOrganization = useCreateOrganization();

  const activeConnectionsResponse = useActiveConnectionsResponse({
    queryOptions: {
      enabled: isChannelPartner,
    },
  });

  const activeConnectionsSelectOptions =
    activeConnectionsResponse.data?.data?.map((item) => {
      return {
        value: item.partner_id,
        label: item.display_name,
        image: item.logo,
      };
    }) || [];

  const closeAllModals = () => {
    setIsCecCustomerExistsModalOpen(false);
    closeMe();
  };

  const form = useForm({
    initialValues: {
      organization_name: '',
      name: '',
      email: '',
      main_partner_id: '',
      choose_admin: isChannelPartner ? 'both' : 'customer',
      auto_admin: isChannelPartner,
      is_sandbox: false,
    },

    validate: yupResolver(schema),
  });

  const onSetCustomerAdmin = (value: CustomerAdmin) => {
    form.setFieldValue('choose_admin', value);

    if (!hasSupportSeat) {
      return;
    }

    if (value === 'customer') {
      form.setFieldValue('auto_admin', false);
      return;
    }

    form.setFieldValue('auto_admin', true);
  };

  const onSubmit = (values: typeof form.values) => {
    createOrganization.mutate(
      {
        organization_name: values.organization_name,
        main_partner_id: values.main_partner_id,
        auto_admin: values.auto_admin,
        email: form.values.choose_admin === 'you' ? '' : form.values.email,
        name: form.values.choose_admin === 'you' ? '' : form.values.name,
        is_sandbox: values.is_sandbox,
      },
      {
        onSuccess: (response) => {
          if (response.cec_customer_exists) {
            setIsCecCustomerExistsModalOpen(true);
          } else {
            closeAllModals();
          }
        },
      }
    );
  };

  const segmentedControlData: SegmentedControlProps['data'] = [
    { value: 'customer', label: 'The customer' },
    { value: 'you', label: 'You', disabled: !hasSupportSeat },
    {
      value: 'both',
      label: 'Both you and the customer',
      disabled: !hasSupportSeat,
    },
  ];

  const onAddSeats = () => {
    requestPlanUpgrade.mutate('Partners > Customers > Add Seats');
  };

  return (
    <>
      <Modal
        opened={!isCecCustomerExistsModalOpen}
        onClose={closeMe}
        title="Create Customer"
        radius="lg"
        size={600}
        padding="xxl"
      >
        <LoadingOverlay
          visible={
            activeConnectionsResponse.isFetching || createOrganization.isLoading
          }
        />

        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack>
            {isChannelPartner && activeConnectionsResponse.isFetched && (
              <Select
                withinPortal
                data={activeConnectionsSelectOptions}
                required
                placeholder="Choose connected brand"
                label="Select brand"
                itemComponent={SelectBrandItem}
                {...form.getInputProps('main_partner_id')}
                data-testid="select-brand-dropdown"
              />
            )}

            <TextInput
              required
              autoFocus
              data-autofocus
              label="Customer organization name"
              data-testid="customer-organization-name-input"
              placeholder="Customer organization name"
              {...form.getInputProps('organization_name')}
            />

            <Checkbox
              {...form.getInputProps('is_sandbox')}
              label="Set as sandbox tenant"
              description="Choose this option if the tenant is for internal demo or testing purposes. Sandbox tenants behave the same as regular customer tenants."
            />

            <Stack>
              <Text>Choose the Admin for the Customer's Portal</Text>

              <SegmentedControl
                data={segmentedControlData}
                value={form.values.choose_admin}
                data-testid="choose-admin"
                onChange={onSetCustomerAdmin}
              />
            </Stack>

            {!hasSupportSeat && (
              <Alert
                icon={<InfoCircle color={theme.colors.amber[6]} />}
                color="gray"
                p="xl"
                radius="md"
              >
                <Stack>
                  <Text>
                    You don't have support seats, so only the customer can be
                    the admin.
                  </Text>

                  <Text
                    align="right"
                    color="gray.9"
                    onClick={onAddSeats}
                    className={classes.addSeats}
                    underline
                  >
                    Add support seats
                  </Text>
                </Stack>
              </Alert>
            )}

            {form.values.choose_admin !== 'you' ? (
              <>
                <TextInput
                  required
                  label="Customer admin name"
                  data-testid="choose-admin-name-input"
                  placeholder="Customer admin name"
                  {...form.getInputProps('name')}
                />

                <TextInput
                  required
                  label="Customer admin email"
                  placeholder="Customer admin email"
                  data-testid="choose-admin-email-input"
                  type="email"
                  {...form.getInputProps('email')}
                />
              </>
            ) : null}

            <Group position="right" pt="lg">
              <Button variant="default" onClick={closeMe}>
                Close
              </Button>

              <Button type="submit" data-testid="submit-button">
                Create
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>

      <ModalCenteredMediaLayout
        opened={isCecCustomerExistsModalOpen}
        onClose={closeAllModals}
        media={<EnvelopeWithShadowSVG />}
        title={
          <Text>
            Customer already exists.
            <br />
            Connection request sent.
          </Text>
        }
        description={
          "The customer you are trying to create already exists. We've sent a connection request email to the customer admin for approval"
        }
        footer={
          <Group position="center">
            <Button
              size="md"
              w={200}
              onClick={closeAllModals}
              data-testid="got-it-button"
            >
              Got it
            </Button>
          </Group>
        }
      >
        {form.values.choose_admin !== 'customer' && (
          <Alert
            color="gray"
            radius="lg"
            icon={<InfoCircle width={18} height={18} />}
          >
            To become an admin, you’ll need to be manually added by by the
            customer admin. Please ensure they add you.
          </Alert>
        )}
      </ModalCenteredMediaLayout>
    </>
  );
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
  label: string;
  image: string;
}

const SelectBrandItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, image, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap py="sm">
        <PartnerAvatar src={image} partnerName={label} size={48} />
        <Text color="blue_gray.9">{label}</Text>
      </Group>
    </div>
  )
);

const useStyles = createStyles({
  addSeats: {
    cursor: 'pointer',
  },
});
