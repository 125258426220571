import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Page404 } from '@portals/framework';

import { useCustomerPortalBrandingPages } from './customer-portal.hooks';
import { BrandingEmptyState } from '../BrandingEmptyState';

export function CustomerPortalPreview() {
  const { privateCloudBrandingPages, cecBrandingPages } =
    useCustomerPortalBrandingPages();

  const accessibleRoutes = useMemo(() => {
    return privateCloudBrandingPages
      .concat(cecBrandingPages)
      .filter((subPage) => subPage.isAccessible);
  }, [cecBrandingPages, privateCloudBrandingPages]);

  return (
    <Routes>
      <Route index element={<BrandingEmptyState />} />

      {accessibleRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.previewComponent}
        />
      ))}

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
